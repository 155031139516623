import { createRoot } from "react-dom/client";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import theme from "@onnit-js/ui/components/themes/light";
import {
    FavoriteContainer,
    FavoriteButton,
} from "@onnit-js/ui/components/buybox/shared/favorites";

/**
 * This file is for the "standalone" favorites javascript that can be used on category list php pages.
 */
const FavoriteButtons = ({ selector }: { selector: string }) => {
    const onnitContext = useOnnitContext();
    const [elements] = useState<any>(() => document.querySelectorAll(selector));

    if (!onnitContext || !elements) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <FavoriteContainer>
                {({ addProduct, removeProduct, isProductOnFavorites }) => [...elements].map((el: HTMLElement) => {
                    const pid = Number(
                        el.getAttribute("data-favorite-pid"),
                    );
                    if (!pid) {
                        return null;
                    }
                    return createPortal(
                        <FavoriteButton
                            iconOnly
                            size="small"
                            productId={pid}
                            addProduct={addProduct}
                            removeProduct={removeProduct}
                            defaultSelected={isProductOnFavorites(pid)}
                        />,
                        el,
                    );
                })}
            </FavoriteContainer>
        </ThemeProvider>
    );
};

window.ONNIT = window.ONNIT || {};

window.ONNIT.favorites = {
    createFavoriteButtons(element, selector: string) {
        if (!selector) {
            console.error(
                "Failed to mount favorites buttons because of missing selector.",
            );
            return;
        }
        const root = createRoot(element);
        root.render(<FavoriteButtons selector={selector} />);
    },
};
